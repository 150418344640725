#calendar .calendar-date-container {
  background: none;
  padding: 16px;
    text-align: left;
}

#calendar .calendar-date-container .calendar-date {
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
}

#calendar .calendar-date-container .calendar-where {
    width: 100%;
    clear: both;
    display: inline-block;
    padding: 0;
    margin: 6px 0 0 0;
    font-size: 14px;
}

#calendar .calendar-date-container .calendar-hour {
    width: 100%;
    clear: both;
    display: inline-block;
    margin-top: 6px;
    font-size: 12px;
}
