.searchBox {
  display: none !important;
}

@media screen and (min-width: 800px) {
  .searchBox {
    display: block !important;
    margin-bottom: 15px !important;
  }
}
