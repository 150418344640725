@media only screen and (max-width: 400px) {
    .stealsFilter .fields {
        flex-flow: nowrap !important;
    }
    .stealsFilter .fields .dropdown {
        /*min-width: auto !important;*/
        /*z-index: 999;*/
        min-width: 150px !important;
    }
    .stealsFilter .fields .dropdown i {
        display: flex;
        flex-direction: row-reverse;
    }

    .stealsFilter .shoesSubModels {
        /*background: red;*/
        margin-top: 20px
    }
}