.imageWrapper {
  border: 1px dotted grey;
  width: 603px;
  height: 603px;
}

#newImage {
  width: 600px;
  height: 600px;
  display: block;
  /*margin: auto;*/
  /*padding: 20px;*/
  position: relative;
  display: block;
  /*margin: 0 auto !important;*/
  paddding: 0 !important;
  /*flex: 1;*/
  background: #fff;
  /*background: rgba(255, 255, 255, 1);*/
  /*background: -moz-linear-gradient(*/
  /*  top,*/
  /*  rgba(255, 255, 255, 1) 0%,*/
  /*  rgba(246, 246, 246, 1) 47%,*/
  /*  rgba(237, 237, 237, 1) 100%*/
  /*);*/
  /*background: -webkit-gradient(*/
  /*  left top,*/
  /*  left bottom,*/
  /*  color-stop(0%, rgba(255, 255, 255, 1)),*/
  /*  color-stop(47%, rgba(246, 246, 246, 1)),*/
  /*  color-stop(100%, rgba(237, 237, 237, 1))*/
  /*);*/
  /*background: -webkit-linear-gradient(*/
  /*  top,*/
  /*  rgba(255, 255, 255, 1) 0%,*/
  /*  rgba(246, 246, 246, 1) 47%,*/
  /*  rgba(237, 237, 237, 1) 100%*/
  /*);*/
  /*background: -o-linear-gradient(*/
  /*  top,*/
  /*  rgba(255, 255, 255, 1) 0%,*/
  /*  rgba(246, 246, 246, 1) 47%,*/
  /*  rgba(237, 237, 237, 1) 100%*/
  /*);*/
  /*background: -ms-linear-gradient(*/
  /*  top,*/
  /*  rgba(255, 255, 255, 1) 0%,*/
  /*  rgba(246, 246, 246, 1) 47%,*/
  /*  rgba(237, 237, 237, 1) 100%*/
  /*);*/
  /*background: linear-gradient(*/
  /*  to bottom,*/
  /*  rgba(255, 255, 255, 1) 0%,*/
  /*  rgba(246, 246, 246, 1) 47%,*/
  /*  rgba(237, 237, 237, 1) 100%*/
  /*);*/
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=0 );*/
}

/*#newImage #box {*/
/*  background: green;*/
/*  !*margin:0 !important;*!*/
/*  !*paddding:0 !important;*!*/
/*  display: block;*/
/*  height: 100%;*/
/*}*/

div#corner-triangle {
  display: block;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 180px 180px 0 0;
  border-color: #051b37 transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  color: white;
  z-index: 1;
  /*text-shadow: 0 0 25px 9px #fff;*/
  /*-webkit-filter: drop-shadow(0 1px 9px #000000);*/
  /*filter: drop-shadow(0 1px 9px #000000);*/
}
#corner-triangle-text {
  position: absolute;
  font-size: 30px;
  z-index: 999;
  top: 45px;
  left: 20px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
  font-family: sans-serif, 'Helvetica Neue', Helvetica, Arial;
  line-height: 1.1;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
/*div#corner-triangle .corner-triangle-text span.corner-triangle-firstline {*/
/*    margin-left: 29px;*/
/*}*/
/*div#corner-triangle .corner-triangle-text a {*/
/*    color: white;*/
/*}*/
/*div#corner-triangle .corner-triangle-text a:hover,*/
/*div#corner-triangle .corner-triangle-text a:link,*/
/*div#corner-triangle .corner-triangle-text a:visited,*/
/*div#corner-triangle .corner-triangle-text a:active,*/
/*div#corner-triangle .corner-triangle-text a:focus {*/
/*    text-decoration: none;*/
/*}*/

#percentage {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-size: 30px;
  background: #051b37;
  color: white;

  width: 0;
  height: 0;
  border-top: 150px solid #ffcc00;
  border-bottom: 150px solid transparent;
  border-right: 150px solid transparent;
}

/*#percentage span {*/
/*    !*position:absolute;*!*/
/*    !*top: 35px;*!*/
/*    width: 100px;*/
/*    left: 5px;*/
/*    text-align: center;*/
/*    font-size: 16px;*/
/*    font-family: arial;*/
/*    transform: rotate(-45deg);*/
/*    display:block;*/
/*}*/

#newImage #logo {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 90px;
  z-index: 1;
}

#newImage #date {
  /*background: green;*/
  font-size: 32px;
  font-weight: bold;
  width: 100%;
  position: absolute;
  top: 23px;
  left: 20px;
  z-index: 1;
}

#extraText {
  position: absolute;
  bottom: 35px;
  font-size: 12px;
  color: grey;
  width: 100%;
  text-align: center;
}

.background-gradient {
  position: absolute;
  bottom: 0;
  height: 180px;
  /* background: red; */
  width: 100%;
  z-index: 1;
  left: 0;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.7707457983193278) 0%, rgba(0, 0, 0, 0) 100%);
}

#newImage #shop {
  /*background: green;*/
  font-size: 20px;
  position: absolute;
  top: 60px;
  width: 100%;
  left: 20px;
  z-index: 1;
}

#promoPrice {
  background: #e2001a;
  padding: 4px 8px;
  border-radius: 6px;
  margin: 0 6px;
  color: white;
  font-weight: bold;
}

#newImage #hour {
  /*background: green;*/
  font-size: 20px;
  position: absolute;
  top: 85px;
  left: 20px;
  z-index: 1;
}

#newImage #imagebox {
  width: 560px;
  height: 340px;
  left: 50%;
  margin-left: -280px;
  /*border: 1px solid green;*/
  position: absolute;
}

#newImage #image {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

#newImage #perfectImage {
  position: absolute;
  width: 520px;
  height: 270px;
  top: 270px;
  left: 50%;
  margin-left: -260px;
  margin-top: -120px;
  border: 1px dotted silver;
}

#newImage #title {
  position: absolute;
  top: 470px;
  width: 100%;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #000;
  text-shadow: -1px -1px 0px #fff, 0px -1px 0px #fff, 1px -1px 0px #fff, -1px 0px 0px #fff, 1px 0px 0px #fff,
    -1px 1px 0px #fff, 0px 1px 0px #fff, 1px 1px 0px #fff;
  z-index: 1;
}

#newImage #retail {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 513px;
  font-size: 18px;
  z-index: 1;
}

#newImage #resell {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 543px;
  font-size: 18px;
  text-shadow: -1px -1px 0px #fff, 0px -1px 0px #fff, 1px -1px 0px #fff, -1px 0px 0px #fff, 1px 0px 0px #fff,
    -1px 1px 0px #fff, 0px 1px 0px #fff, 1px 1px 0px #fff;
  z-index: 1;
}

#newImage #branding {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  color: grey;
  z-index: 1;
}
