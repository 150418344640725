/* Segment */
.segmentInverted {
  text-align: center;
  background-color: #f2f3f5 !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.segmentInverted .tabs .menu .active {
  background: rgb(242, 243, 245) !important;
  background: linear-gradient(0deg, rgba(242, 243, 245, 1) 40%, rgba(255, 255, 255, 1) 100%) !important;
}

.gradient {
  background: rgb(242, 243, 245) !important;
  background: linear-gradient(0deg, rgba(242, 243, 245, 1) 40%, rgba(255, 255, 255, 1) 100%) !important;
}

/* TABS */
.tabs .ui.tabular.menu .item:first-child {
  margin-left: auto;
}

.tabs .ui.tabular.menu .item:last-child {
  margin-right: auto;
}

.tabs .ui.tabular.menu .item {
  padding-left: 10px;
  padding-right: 10px;
}

.tabs .tab {
  border: none !important;
  padding: 0 !important;
}
@media screen and (max-width: 768px)  {
  .ui.container {
    /*background: green;*/
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
  }
  .ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ui.two.cards>.card {
    /*background: red;*/
    margin-left: 0.6em !important;
    margin-right: 0.6em !important;
  }
}

/*@media screen and (min-width: 768px) and (max-width: 991px) {*/
/*  .ui.container {*/
/*    width: 768px;*/
/*    !*width: 100% !important;*!*/
/*    !*max-width: 900px !important;*!*/
/*    background: red;*/
/*    !*margin-left: 0.5em !important;*!*/
/*    !*margin-right: 0.5em !important;*!*/
/*  }*/
/*}*/


/* Mobile */

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="tablet only"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large monitor only"]:not(.mobile),
  [class*="widescreen monitor only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
}


/* Tablet / iPad Portrait */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  [class*="mobile only"]:not(.tablet),
  [class*="tablet hidden"],
  [class*="computer only"]:not(.tablet),
  [class*="large monitor only"]:not(.tablet),
  [class*="widescreen monitor only"]:not(.tablet),
  [class*="or lower hidden"]:not(.mobile) {
    display: none !important;
  }
}


/* Computer / Desktop / iPad Landscape */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  [class*="mobile only"]:not(.computer),
  [class*="tablet only"]:not(.computer),
  [class*="computer hidden"],
  [class*="large monitor only"]:not(.computer),
  [class*="widescreen monitor only"]:not(.computer),
  [class*="or lower hidden"]:not(.tablet):not(.mobile) {
    display: none !important;
  }
}


/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
  [class*="mobile only"]:not([class*="large monitor"]),
  [class*="tablet only"]:not([class*="large monitor"]),
  [class*="computer only"]:not([class*="large monitor"]),
  [class*="large monitor hidden"],
  [class*="widescreen monitor only"]:not([class*="large monitor"]),
  [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
    display: none !important;
  }
}


/* Widescreen Monitor */

@media only screen and (min-width: 1920px) {
  [class*="mobile only"]:not([class*="widescreen monitor"]),
  [class*="tablet only"]:not([class*="widescreen monitor"]),
  [class*="computer only"]:not([class*="widescreen monitor"]),
  [class*="large monitor only"]:not([class*="widescreen monitor"]),
  [class*="widescreen monitor hidden"],
  [class*="widescreen monitor or lower hidden"] {
    display: none !important;
  }
}

