.card .content .description {
  overflow-wrap: break-word;
}

@media only screen and (max-width: 400px) {
  .shortTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
  }
}

