/*.search-box-dropdown {*/
/*    min-width: 120px !important;*/
/*}*/

.fluidSearchBox {
  width: 100% !important ;
}

.search-box-shop {
  min-width: 150px !important;
  /*max-width: 150px !important;*/
}

.search-box-gender {
  min-width: 110px !important;
  /*max-width: 110px !important;*/
}

.search-box-brand {
  min-width: 130px !important;
  /*max-width: 250px !important;*/
}

.search-box-sizes {
  min-width: 110px !important;
}

.search-box-color {
  min-width: 130px !important;
  /*max-width: 130px !important;*/
}

.search-box-sort {
  min-width: 130px !important;
}

.ui.attached.tabular.menu a:first-child {
  margin-left: auto;
}

.ui.attached.tabular.menu a:last-child {
  margin-right: auto;
}
